import { PageProps, graphql } from 'gatsby';
import React from 'react';

import Profile from '~/components/Profile';
import Seo from '~/components/Seo';
import { useSeo } from '~/hooks/useSeo';
import Layout from '~/layout';


const AboutPage = ({ data, location }: PageProps<GatsbyTypes.AboutPageQuery>) => {
  const siteMetadata = useSeo().site?.siteMetadata;

  const siteUrl = data.site?.siteMetadata?.siteUrl ?? '';
  const siteTitle = data.site?.siteMetadata?.title ?? '';
  const siteThumbnail = data.site?.siteMetadata?.thumbnail;

  const meta: Metadata[] = [];
  if (siteThumbnail) {
    const properties = ['og:image', 'twitter:image'];

    for (const property of properties) {
      meta.push({
        property,
        content: `${siteUrl}${siteThumbnail}`,
      });
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        lang='en'
        title={siteMetadata?.title ?? ''}
        description={siteMetadata?.description ?? ''}
        meta={meta}
        noSiteName
      />
      <Profile />
      <h1>소개</h1>
      <p>자기 주도적으로 업무 수행하기 좋아하고, 기술적인 성장을 목표로 하고 있습니다. 같은 문제라 할지라도 최대한 다양한 시도를 해보며, 문제 상황에서 내가 사용할 수 있는, 혹은 판단할 수 있는 선택지를 늘리는 것을 좋아합니다.</p>
      <p>업무를 수행하면서 문제가 있을 때 가장 효율적인 해결책을 먼저 생각하지만, 시간이 허락한다면 조금 더 돌아가더라도 근본적인 해결 방법에 관해 탐구하고자 노력하고 있습니다.</p>
      <h1>살아온 길</h1>
      <p>초등학생 시절, 알고리즘 문제를 온종일 고민하여 해결했을 때의 쾌감을 잊지 못해 컴퓨터 공학에 관심을 가지게 되었습니다. 그 당시 유명하던 XpressEngine를 이용해 사이트를 구축하기 위해 php를 독학하고, 남는 집 컴퓨터에 LAMP stack을 설치하여 직접 운영할 정도로 웹, 서버에 푹 빠져있었습니다.</p>
      <p>군대에서는 뜻하지 않게 프로그래밍 작성병으로 근무하였습니다. 인트라넷 웹 체계를 유지보수하거나 개발하는 직무를 맡아 Spring Framework / Oracle Database 등의 지식을 독학하며 이전까지 가지고 있던 꼬마 시절 지식과 합치며 배움의 기쁨을 느꼈습니다.</p>
      <p>대학교 졸업 후에는 차량용 임베디드 플랫폼 관련 회사에 취업였습니다. 경험이 쌓이면서 평소 원하던 소프트웨어 개발과는 거리가 멀다는 것을 깨달았으나, 주어진 자리에서 할 수 있는 것을 하자는 생각으로 업무 자동화를 통한 업무 효율 증진, 임베디드 플랫폼 CI/CD 관련 프로세스 개발 및 정립 등을 스스로 수행하였습니다.</p>
      <p>그러던 중, 이대로는 웹 분야로 나아갈 수 없다는 생각이 들어 여러 회사에 신입으로 지원하여 현재 퍼포먼스 마케팅 관련 스타트업으로 이직하여 Python 기반의 FastAPI 관련 인하우스 툴 프로젝트를 수행하고 있습니다.</p>
      <h1>참고</h1>
      <p>본 블로그의 글은 지금의 제 의견과 완전히 다를 수 있습니다. 일방적인 비난이 아닌 합리적인 비판은 거리낌 없이 수용하려고 합니다.</p>
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
        siteUrl
        thumbnail
      }
    }
  }
`;
